@font-face {
  font-family: "felix_titlingregular";
  src: url("felixti-webfont.woff2") format("woff2"),
    url("felixti-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "felix_titlingregular", sans-serif;
  background-color: #222222;
  color: white;
  overflow-x: hidden;
}

/* ----------------------Scrollbar-------------------------------------------------------- */

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #999;
}
