.Navbar {
  box-shadow: 0.5px 0.5px #999;
  position: fixed;
  top: 0;
  padding: 0.5rem 2rem;
  z-index: 150;
  display: flex;
  align-items: center;
  width: 100%;
  background-color: #222222;
  justify-content: center;
  font-family: "PT SERIF", "sans-serif";
  .logo {
    order: 4;
    img {
      width: 6.9rem;
    }
  }
  .nav-menu {
    display: none;
    list-style: none;
    gap: 2rem;
    font-size: 1.2rem;
    margin: 0;
    margin-left: auto;
    li {
      a {
        color: white;
        text-decoration: none;
        &:hover {
          color: #ffd400;
        }
      }
    }
  }
  .hamburger {
    margin-right: auto;
    order: -1;
    ._icon {
      font-size: 1.5rem;
      cursor: pointer;
      color: rgb(243, 243, 243);
    }
  }
  .side-menu {
    background-color: rgb(153, 153, 153, 0.9);
    position: absolute;
    top: 5rem;
    left: 0;
    padding: 0.8rem 1rem 0.8rem 0;
    border-radius: 15px;
    ul {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      list-style: none;
      gap: 0.5rem;
      li {
        a {
          text-decoration: none;
          color: rgb(243, 243, 243);
          font-weight: 800;
        }
      }
    }
  }
  .flags {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 0.5rem;

    gap: 0.5rem;
    order: -1;
    margin-right: 6%;
    span {
      width: 1rem;
      &:hover {
        cursor: pointer;
        opacity: 0.6;
      }
    }
  }
}

@media only screen and (min-width: 940px) {
  .Navbar {
    padding: 0.75rem 3%;
    justify-content: space-between;
    .flags {
      display: flex;
      margin-left: 35vw;
      order: 2;
      padding: 1rem;
      border-radius: 1rem;
      gap: 0.8rem;
      margin-right: 8%;
      span {
        width: 1.5rem;
      }
    }
    .side-menu {
      display: none;
    }
    .logo {
      order: 1;
      margin-left: 0;
    }
    .nav-menu {
      order: 3;
      text-align: center;
      display: flex;
    }
    .hamburger {
      display: none;
      ._icon {
        display: none;
      }
    }
  }
}
