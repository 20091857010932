.AboutUs {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  overflow: hidden;
  .about-left {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 5rem;
    img {
      margin-top: 2rem;
      width: 40%;
      height: 40%;
    }
    h1 {
      font-family: "PT SERIF";
      margin-bottom: 1rem;
    }
    p {
      font-family: "PT SERIF";
    }
    .counts {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 3rem;
      margin-top: 5rem;
      .count {
        padding: 2rem;

        border-radius: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        h2 {
          display: flex;
          border: none;
        }
      }
    }
  }
  .about-right {
    width: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

@media only screen and (min-width: 940px) {
  .AboutUs {
    width: 100%;
    display: flex;
    flex-direction: row;
    .about-left {
      width: 50%;
      img {
        height: auto;
      }
      .counts {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8rem;
        margin-top: 5rem;
        .count {
          padding: 2rem;
          border-radius: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          p {
            clear: both;
            display: inline-block;
            overflow: hidden;
            white-space: nowrap;
          }
          h2 {
            display: flex;
            border: none;
          }
        }
      }
    }
    .about-right {
      width: 50%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
