.Body {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  position: absolute;
  z-index: 100;
}

@media only screen and (min-width: 940px) {
}
