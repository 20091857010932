.Carousel-wrapper {
  width: 100%;

  margin-top: 4.2rem;
  margin-bottom: 0.2rem;
  .mouse {
    width: 30px;
    height: 50px;
    border: 2px solid #ffffff;
    border-radius: 60px;
    position: absolute;
    z-index: 20;
    margin-left: 45%;
    margin-top: -6rem;
    &::before {
      content: "";
      width: 5px;
      height: 5px;
      position: absolute;
      top: 10px;
      left: 50%;
      transform: translateX(-50%);
      background-color: #ffffff;
      border-radius: 50%;
      opacity: 1;
      animation: wheel 2s infinite;
      -webkit-animation: wheel 2s infinite;
    }
  }

  @keyframes wheel {
    to {
      opacity: 0;
      top: 60px;
    }
  }

  @-webkit-keyframes wheel {
    to {
      opacity: 0;
      top: 60px;
    }
  }
  .carousel-item {
    position: relative;
    z-index: -2;
  }
}
.controllers {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  top: 10rem;
  gap: 5rem;
  z-index: 10;
  padding: 0 3rem;
  button {
    background-color: transparent;
    border: none;
    color: white;
    opacity: 0.5;
    svg {
      font-size: 2rem;
    }
    &:hover {
      opacity: 1;
    }
  }
}

@media only screen and (min-width: 940px) {
  .Carousel-wrapper {
    margin-top: 0;
    .mouse {
      width: 50px;
      height: 90px;
      border: 2px solid #ffffff;
      border-radius: 60px;
      position: absolute;
      z-index: 20;
      margin-left: 48.5%;
      margin-top: -15rem;
      &::before {
        content: "";
        width: 12px;
        height: 12px;
        position: absolute;
        top: 10px;
        left: 50%;
        transform: translateX(-50%);
        background-color: #ffffff;
        border-radius: 50%;
        opacity: 1;
        animation: wheel 2s infinite;
        -webkit-animation: wheel 2s infinite;
      }
    }
  }
  .controllers {
    top: 55%;
    button {
      background-color: transparent;
      border: none;
      color: white;
      svg {
        font-size: 3rem;
      }
    }
  }
}
